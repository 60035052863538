<template>
  <ListRow>
    <td>
      <span :class="['badge_state', activeClass]">{{ activeText }}</span>
    </td>
    <td>{{ rowData.templateTypeName }}</td>
    <td>{{ rowData.templateId }}</td>
    <td class="td_ellip" :data-ellip="rowData.templateName">
      <router-link
        :to="toPath"
        class="link_subject"
        :data-ellip="rowData.templateName"
        v-html="rowData.templateName"
      />
    </td>
    <td class="align_left">
      {{ rowData.templateDesc }}
    </td>
    <td>
      {{ rowData.regId }}
    </td>
    <td>
      {{ rowData.regDate | dateTimeStringFormat }}
    </td>
    <td>
      <button
        v-if="!isDisableDelBtn"
        type="button"
        class="btn_fourthly btn_small"
        @click="onClickDelete"
      >
        삭제
      </button>
    </td>
  </ListRow>
</template>

<script>
import ListRow from "@/components/shared/ListRow";
import SelectBox from "@/components/common/selectBox/SelectBox";
import ListLineMixin from "@/mixins/ListLineMixin";
import { makePathWithQuery } from "@/utils/urlUtils";
import { ALERT_ACTION } from "@/store";

export default {
  name: "SystemPerformanceSettingListLine",
  components: {
    ListRow,
    SelectBox,
  },
  mixins: [ListLineMixin],
  computed: {
    toPath() {
      const { templateId } = this.rowData;
      const path = makePathWithQuery(
        this.$routerPath.SYSTEM_PERFORMANCE_SETTING,
        templateId,
        this.$route.query,
      );
      return path;
    },
    isDisableDelBtn() {
      return this.rowData.activeYn;
    },
    activeClass() {
      if (this.rowData.activeYn) return "on";
      return "disabled";
    },
    activeText() {
      if (!this.rowData.activeYn && typeof this.rowData.activeYn !== "boolean") {
        return "";
      }
      if (this.rowData.activeYn) {
        return "활성";
      } else {
        return "비활성";
      }
    },
  },
  methods: {
    onClickDelete() {
      const params = {
        text: "평가 템플릿을 삭제 하시겠습니까? ",
        onClickY: this.onClickDeleteOk,
      };

      this.$store.dispatch(ALERT_ACTION.SHOW_ALERT_YN, params);
    },
    onClickDeleteOk() {
      this.$emit("onClickDeleteTemplate", this.rowData);
    },
  },
};
</script>
