<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 id="kakaoBody" class="tit_section">업체(평가)관리</h2>
      </div>
      <div class="body_section">
        <Tabs
          :keyParam="KEY_PARAM"
          :dataList="TAB_DATA_LIST"
          :currentValue.sync="currentTabValue"
          @onChangeTabValue="onChangeTabValue"
        />

        <SystemPerformanceFilterBarSetting
          :templateType="TEMPLATE_TYPE"
          :keywordDatas="KEYWORD_DATAS"
          :defaultTemplateTypeId.sync="defaultTemplateTypeId"
          :defaultKeywordSelectedId="defaultKeywordSelectedId"
          :defaultKeywordInputText="defaultKeywordInputText"
          @onClickSearch="onClickSearch"
        />

        <TableHead>
          <template v-slot:headLeft>
            <button type="button" class="btn_tertiary btn_medium" @click="onClickGoToWrite">
              <span class="ico_purchase ico_cross_w" />신규 등록
            </button>
          </template>
          <!--          <template v-slot:headRight>-->
          <!--            -->
          <!--            <SelectBoxPageRange-->
          <!--              :currentMaxRowCount.sync="size"-->
          <!--              @update:currentMaxRowCount="onChangeRowCount"-->
          <!--            />-->
          <!--          </template>-->
        </TableHead>
        <ListPage
          :list="dataList"
          :currentPageIndex="currentPageIndex"
          :totalPageCount="totalPageCount || 0"
          :maxRowCount="size"
          :maxPaginationCount="maxPaginationCount"
          :showPagination="false"
          @onChangePage="onChangePage"
        >
          <template v-slot:colgroup>
            <col style="width: 100px" />
            <col style="width: 120px" />
            <col style="width: 120px" />
            <col style="width: 200px" />
            <col style="width: 300px" />
            <col style="width: 150px" />
            <col style="width: 150px" />
            <col style="width: 100px" />
          </template>
          <template v-slot:tr>
            <th>상태</th>
            <th>평가구분</th>
            <th>평가번호</th>
            <th>평가명</th>
            <th>비고</th>
            <th>등록자</th>
            <th>등록일자</th>
            <th></th>
          </template>
          <template v-slot:row="slotProps">
            <SystemPerformanceSettingListLine
              :rowData="slotProps.rowData"
              @onClickDeleteTemplate="onClickDeleteTemplate"
            />
          </template>
          <template v-slot:emptyList>
            <tr>
              <td colspan="8" class="td_empty">검색 결과가 없습니다</td>
            </tr>
          </template>
        </ListPage>
      </div>
    </div>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";
import Tabs from "@/components/common/tabs/Tabs";
import SystemPerformanceMixin from "@/components/admin/system/performance/SystemPerformanceMixin";
import ListPageMixin from "@/mixins/ListPageMixin";
import SystemPerformanceFilterBarSetting from "@/components/admin/system/performance/SystemPerformanceFilterBarSetting";
import TableHead from "@/components/shared/TableHead";
import SelectBoxPageRange from "@/components/common/selectBox/SelectBoxPageRange";
import ListPage from "@/components/shared/ListPage";
import SystemPerformanceSettingListLine from "@/components/admin/system/performance/SystemPerformanceSettingListLine";
import ApiService from "@/services/ApiService";

export default {
  name: "SystemPerformanceSettingList",
  components: {
    SystemPerformanceSettingListLine,
    ListPage,
    SelectBoxPageRange,
    TableHead,
    SystemPerformanceFilterBarSetting,
    PageWithLayout,
    Tabs,
  },
  mixins: [SystemPerformanceMixin, ListPageMixin],
  data() {
    return {
      currentTabValue: "SYSTEM_PERFORMANCE_SETTING",
      API_PATH: this.$apiPath.ST_EVALUATE,

      TEMPLATE_TYPE: [
        { name: "전체", code: "" },
        { name: "파트너평가", code: "PA" },
        { name: "수행도평가", code: "PE" },
      ],
      KEYWORD_DATAS: [
        { name: "평가번호", code: "templateId", id: "templateId" },
        { name: "평가명", code: "templateName", id: "templateName" },
      ],
      defaultTemplateTypeId: "",
      defaultKeywordSelectedId: "templateName",
      defaultKeywordInputText: "",
    };
  },
  beforeMount() {
    const params = this.checkUrlQuery();

    this.getDataInit(params);
  },
  methods: {
    checkUrlQuery() {
      const { query } = this.$route;

      // const objPaging = this.checkQueryPaging(query);

      const objKeyword = this.checkQueryKeywords(query, this.KEYWORD_DATAS);

      let obj = {};
      if (query.templateType) {
        obj.templateType = query.templateType;
        this.defaultTemplateTypeId = obj.templateType;
      }

      return Object.assign({}, objKeyword, obj);
    },
    getFilterbarParams(objData) {
      const { keywordSelectedId, keywordInputText } = objData;

      let params = {};

      if (keywordSelectedId && keywordInputText) {
        params[keywordSelectedId] = keywordInputText;
      }
      params.templateType = this.defaultTemplateTypeId;
      return params;
    },
    onClickSearch(objData) {
      let params = this.getFilterbarParams(objData);

      // params.page = '1';
      // params.size = this.size;

      this.replace(params);
    },
    // 신규 템플릿 등록
    onClickGoToWrite() {
      this.$router.push({ path: this.$routerPath.SYSTEM_PERFORMANCE_WRITE });
    },
    // 평가 템플릿 삭제
    onClickDeleteTemplate(obj) {
      this.deleteTemplate(obj);
    },
    async deleteTemplate(objData) {
      const { templateId, activeYn } = objData;
      if (!templateId) return;

      if (activeYn) {
        this.alert("활성화된 평가 템플릿은 삭제가 불가능합니다.");
        return;
      }

      const path = `${this.$apiPath.ST_EVALUATE}/${templateId}`;

      const result = await ApiService.shared.deleteData(path);

      if (result.code !== "200") {
        this.alert(result.text);
        return;
      }

      this.alert("평가 템플릿 삭제가 완료되었습니다.");

      const params = this.checkUrlQuery();
      this.onClickSearch(params);
    },
  },
};
</script>
