<template>
  <FilterBarBase @onClickSearch="onClickSearch" @onClickReset="onClickReset">
    <dl class="w152">
      <dt>상태</dt>
      <dd>
        <SelectBox
          class="w152"
          :dataList="templateType"
          :value.sync="statusId"
          @onChange="onChangeTemplateType"
        />
      </dd>
    </dl>
    <dl>
      <dt>검색어</dt>
      <dd>
        <div class="group_form">
          <SelectBox class="w152" :dataList="keywordDatas" :value.sync="keywordSelectedId" />
          <Input class="w336" :value.sync="keywordInputText" @onEnterKey="onClickSearch" />
        </div>
      </dd>
    </dl>
  </FilterBarBase>
</template>

<script>
import FilterBarBase from "@/components/shared/filterBar/FilterBarBase";
import Input from "@/components/common/input/Input";
import SelectBox from "@/components/common/selectBox/SelectBox";

import FilterBarMixin from "@/mixins/FilterBarMixin";

export default {
  name: "SystemPerformanceFilterBarSetting",
  components: {
    FilterBarBase,
    Input,
    SelectBox,
  },
  mixins: [FilterBarMixin],
  props: {
    templateType: Array,
    defaultTemplateTypeId: String,
  },
  data() {
    return {
      statusId: this.defaultTemplateTypeId,
      keywordSelectedId: this.defaultKeywordSelectedId,
    };
  },
  methods: {
    onClickSearch() {
      let obj = this.getCommonFilterbarData();

      this.$emit("onClickSearch", obj);
    },
    onChangeTemplateType(val) {
      this.$emit("update:defaultTemplateTypeId", val);
    },
    onClickReset() {
      this.onClickResetFilterBar();
      this.statusId = "";
      this.keywordSelectedId = this.defaultKeywordSelectedId;
    },
  },
};
</script>
